/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vuetify from "vuetify";
import Vue from "vue";

import router from "./routes/routes";
import store from "./store";

require("./bootstrap");

window.Vue = require("vue").default;

Vue.use(Vuetify);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("main-content", require("./App.vue").default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
    router: router,
    store: store,
    vuetify: new Vuetify({
        theme: {
            themes: {
                light: {
                    primary: "#119DA4",
                    secondary: "#171b34",
                    accent: "3D87E4",
                },
            },
        },
    }),
});
